import React, { useEffect, useState } from 'react'
import { BarChart, Button, Text } from 'components/UI'
import { OrderDateFilter } from 'components/filters'
import moment from 'moment'
import { getProductById } from 'hooks'
import { PreviewCard } from 'components/product'
import imgBase from 'public/default-product-image.png'
import s from './partner-drawer.module.scss'
import { PaymentContent, TableContent } from './parts'
import { useGetProgramDetail, useGetStatistics, useWithdrawMoney } from '../hooks'
import { PartnersTabKeys } from '../consts'

interface IPartnerDrawerProps {
  activeTabFilter: string | null
  programId: string
  refetchTable?: () => void
}

interface IPeriodValue {
  startDate: string
  endDate: string
}

const PartnerDrawer = ({ activeTabFilter, programId, refetchTable }: IPartnerDrawerProps) => {
  const { headerData, partners, paymentData, refetchDetails } = useGetProgramDetail({ programId })

  const [imageSrc, setImageSrc] = useState<string>(headerData?.productImage ?? imgBase)
  const [periodValue, setPeriodValue] = useState<IPeriodValue | null>(null)
  const [isProductInfoOpen, setIsProductInfoOpen] = useState<boolean>(false)

  const onDateChange = (startDate: string | null, endDate?: string) => {
    setPeriodValue(
      startDate && endDate
        ? { startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') }
        : null
    )
  }

  const { statistics } = useGetStatistics({ programId, ...periodValue })

  const onSuccessWithdraw = () => {
    refetchDetails()
    refetchTable?.()
  }
  const { mutate } = useWithdrawMoney({ onSuccessFunc: onSuccessWithdraw })

  const { characteristics, mainInfo, description, images } = getProductById(headerData.productId)

  const isPaymentDrawer = activeTabFilter === PartnersTabKeys.Withdrawals

  const withdrawMoney = () => {
    mutate({
      shopId: paymentData?.shopId,
      withdrawalId: paymentData?._id
    })
  }

  useEffect(() => {
    setImageSrc(headerData?.productImage)
  }, [headerData?.productImage])

  return (
    <div className={s['partner-drawer']}>
      <div className={s['partner-drawer__header']}>
        <div className={s['partner-drawer__header__container']}>
          <img
            className={s['partner-drawer__header__img']}
            src={imageSrc}
            alt='product'
            onError={() => setImageSrc(imgBase)}
          />
          <Text size='xl'>{headerData?.productName}</Text>
          <Button
            style={{ marginLeft: 4, whiteSpace: 'nowrap', height: 'fit-content' }}
            size='xs'
            color='white'
            onClick={() => setIsProductInfoOpen(true)}
          >
            Открыть карточку
          </Button>
        </div>
      </div>
      <div className={s['partner-drawer__content']}>
        <div className={s['partner-drawer__content__info']}>
          {isPaymentDrawer ? <PaymentContent paymentData={paymentData} /> : <TableContent partners={partners} />}
        </div>
        <div className={s['partner-drawer__content__chart']}>
          <BarChart height='288px' data={statistics} />
          <div className={s['partner-drawer__content__chart-date']}>
            <OrderDateFilter label='Период' onFilterChange={onDateChange} disabled={false} position='right' />
          </div>
        </div>
        {isPaymentDrawer && paymentData?.status !== 'Completed' && (
          <div className={s['partner-drawer__content__actionButton']}>
            <Button onClick={withdrawMoney}>Списать баллы</Button>
          </div>
        )}
      </div>
      <PreviewCard
        data={{
          characteristics,
          mainInfo,
          description,
          images
        }}
        isOpen={isProductInfoOpen}
        onClose={() => setIsProductInfoOpen(false)}
      />
    </div>
  )
}

export default PartnerDrawer
