import React from 'react'
import cx from 'classnames'
import { Text } from 'components/UI'
import { EOrderStatus } from 'models'
import s from './orders.module.scss'

interface IOrderStatusProps {
  status: EOrderStatus
  className?: string
}

const OrderStatusLabel = ({ status, className }: IOrderStatusProps) => {
  const classNameStatus = {
    Создан: 'create',
    Оплачено: 'paid',
    'В сборке': 'in-progress',
    'В доставке': 'in-delivery',
    Доставлено: 'delivered',
    'Отменено покупателем': 'canceled-buyer',
    'Отменено продавцом': 'canceled-seller',
    // статусы для Партнерской программы
    'Ожидает оплаты': 'in-progress',
    Завершено: 'paid'
  }

  return (
    <Text size='xxs' className={cx(s['order-status-label'], s[classNameStatus[status]], className)}>
      {status}
    </Text>
  )
}

export default OrderStatusLabel
