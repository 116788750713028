import React from 'react'
import { Text } from 'components/UI'
import { IStatisticsData } from 'models'
import s from './style.module.scss'

const CustomLegend = ({ data }: { data: IStatisticsData }) => (
  <div className={s['custom-legend']}>
    <div className={s['custom-legend__ordered']}>
      <Text size='sm' className={s['custom-legend__title']} as='p' weight='medium' align='center'>
        {data.secondLabel}
      </Text>
      <Text size='md' as='p' weight='medium' align='start'>
        {data.totalForSecondLabel} {data?.isCurrencyLabel && '₽'}
      </Text>
    </div>
    <div className={s['custom-legend__delivered']}>
      <Text size='sm' as='p' className={s['custom-legend__title']} weight='medium' align='center'>
        {data.firstLabel}
      </Text>
      <Text size='md' as='p' weight='medium' align='start'>
        {data.totalForFirstLabel} {data?.isCurrencyLabel && '₽'}
      </Text>
    </div>
  </div>
)

export default CustomLegend
