export interface IFormattedStatisticsForDashboard {
  date: string
  delivered: number
  created: number
}

export interface IStatisticsDataForDashboard {
  formattedStatistics: IFormattedStatisticsForDashboard[]
  totalPriceForDelivered: number
  totalPriceForCreated: number
}

export const transformDataForDashboard = (obj: IStatisticsDataForDashboard) => ({
  formattedStatistics: obj.formattedStatistics.map((stat: IFormattedStatisticsForDashboard) => ({
    date: stat.date,
    firstLabel: stat.delivered,
    secondLabel: stat.created
  })),
  totalForFirstLabel: obj.totalPriceForDelivered,
  firstLabel: 'Доставлено товаров на сумму',
  totalForSecondLabel: obj.totalPriceForCreated,
  secondLabel: 'Заказано товаров на сумму',
  isCurrencyLabel: true
})
