import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { routes } from 'constants/routes'
import Layout from 'components/layouts/Main'
import PartnersPage from 'pages/PartnersPage'
import { useAppSelector } from './redux/hook'
import AuthPage from './pages/AuthPage'
import RegistrationPage from './pages/RegistrationPage'
import HomePage from './pages/HomePage'
import DashboardPage from './pages/DashboardPage'
import OrdersPage from './pages/OrdersPage'
import ProductsPage from './pages/ProductsPage'
import ProductAddNewPage from './pages/ProductAddNewPage'
import ProductEditPage from './pages/ProductEditPage'
import ProductAddPage from './pages/ProductAddPage'
import Reviews from './pages/ReviewsPage'
import Chat from './pages/Chat/Chat'
import SettingsPage from './pages/SettingsPage'

export const Router = () => {
  const { token, isAuthCompleted } = useAppSelector(({ store }) => store.auth)

  const isAuth = Boolean(token) && isAuthCompleted

  if (!isAuth) {
    return (
      <Routes>
        <Route path={routes.HOME} element={<HomePage />} />
        <Route path={routes.AUTH} element={<AuthPage />} />
        <Route path={routes.REGISTRATION} element={<RegistrationPage />} />
        <Route path='*' element={<Navigate to={routes.HOME} replace />} />
      </Routes>
    )
  }

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path={routes.DASHBOARD} element={<DashboardPage />} />
        <Route path={routes.ORDERS} element={<OrdersPage />} />
        <Route path={routes.PRODUCTS} element={<ProductsPage />} />
        <Route path={routes.PARTNERS} element={<PartnersPage />} />
        <Route path={routes.PRODUCTS_ADD} element={<ProductAddPage />} />
        <Route path={routes.PRODUCTS_ADD_NEW} element={<ProductAddNewPage />} />
        <Route path={routes.PRODUCTS_EDIT} element={<ProductEditPage />} />
        <Route path={routes.REVIEWS} element={<Reviews />} />
        <Route path={routes.CHATS} element={<Chat />}>
          <Route path={routes.CHATS_ID} element={<Chat />} />
        </Route>
        <Route path={routes.SETTINGS} element={<SettingsPage />} />
        <Route path='*' element={<Navigate to={routes.DASHBOARD} replace />} />
      </Route>
    </Routes>
  )
}
