export interface IFormattedStatisticsForDashboard {
  date: string
  sales: number
  clicks: number
}

export interface IStatisticsDataForDashboard {
  formattedStatistics: IFormattedStatisticsForDashboard[]
  totalPriceForSales?: number
  totalPriceForClicks?: number
}

export const transformDataForPartners = (obj: IStatisticsDataForDashboard) => {
  let totalForFirstLabel = 0
  let totalForSecondLabel = 0
  const formattedStatistics = obj.formattedStatistics.map((stat: IFormattedStatisticsForDashboard) => {
    totalForFirstLabel += stat.sales
    totalForSecondLabel += stat.clicks
    return {
      date: stat.date,
      firstLabel: stat.sales,
      secondLabel: stat.clicks
    }
  })
  return {
    formattedStatistics,
    totalForFirstLabel,
    firstLabel: 'Продажи',
    totalForSecondLabel,
    secondLabel: 'Переходы',
    isCurrencyLabel: false
  }
}
