import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { CellContext, ColumnDef, SortingState } from '@tanstack/react-table'
import { useAppSelector } from 'redux/hook'
import { useQueryParams } from 'hooks'
import { EOrderStatus, IProductImage, TPartnerProgramList, TProductsTabCount } from 'models'
import {
  Button,
  Drawer,
  DropDownList,
  Modal,
  PageEmpty,
  PageHeader,
  PageTabs,
  Pagination,
  Search,
  Table,
  TableSkeleton
} from 'components/UI'
import { ProductImage } from 'components/product'
import $api from 'components/http/axios'
import OrderStatusLabel from 'components/orders/OrderStatusLabel'
import { PartnerDrawer, PartnerModal } from 'components/partners'
import moment from 'moment'
import { useGetTableData } from 'components/partners/hooks'
import { PartnersTabKeys } from 'components/partners/consts'
import s from './partners-page.module.scss'

type TSort =
  | 'productName_asc'
  | 'productName_desc'
  | 'discountPercentage_asc'
  | 'discountPercentage_desc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'endDate_asc'
  | 'endDate_desc'
  | 'status_asc'
  | 'status_desc'

interface ITab {
  name: string
  key: string
  labelKey: string
  labelColor?: 'red' | 'blue'
  disabled?: boolean
}

const tabs = [
  { name: 'Активные', key: PartnersTabKeys.Active },
  { name: 'Архив', key: PartnersTabKeys.Archived },
  { name: 'Платежи', key: PartnersTabKeys.Withdrawals, labelColor: 'blue' }
] as ITab[]

const paymentStatus = {
  Pending: EOrderStatus.WAITING_FOR_PAID,
  Completed: EOrderStatus.DONE
}

const columns: ColumnDef<TPartnerProgramList>[] = [
  {
    accessorKey: 'index',
    header: '№',
    size: 65,
    enableSorting: false
  },
  {
    accessorKey: 'image',
    header: 'Товар',
    size: 58,
    minSize: 58,
    enableSorting: false,
    cell: (info: CellContext<TPartnerProgramList, unknown>) => {
      const image = info.getValue() as IProductImage
      return <ProductImage src={image?.link} width={26} height={26} className={s['image-cell-wrapper']} />
    }
  },
  {
    accessorKey: 'name',
    header: '',
    minSize: 300
  },
  {
    accessorKey: 'discountPercentage',
    header: 'Скидка',
    size: 150,
    minSize: 150,
    cell: (info: CellContext<TPartnerProgramList, unknown>) => info?.getValue()
  },
  {
    accessorKey: 'createdAt',
    header: 'Дата создания',
    size: 150,
    minSize: 150,
    cell: (info: CellContext<TPartnerProgramList, unknown>) => moment(info?.getValue() as string).format('DD.MM.YY')
  },
  {
    accessorKey: 'endDate',
    header: 'Срок действия',
    size: 150,
    minSize: 150,
    cell: (info: CellContext<TPartnerProgramList, unknown>) => moment(info?.getValue() as string).format('DD.MM.YY')
  }
]

const columnsForPayments: ColumnDef<TPartnerProgramList>[] = [
  {
    accessorKey: 'index',
    header: '№',
    size: 65,
    enableSorting: false
  },
  {
    accessorKey: 'image',
    header: 'Товар',
    size: 58,
    minSize: 58,
    enableSorting: false,
    cell: (info: CellContext<TPartnerProgramList, unknown>) => {
      const image = info.getValue() as IProductImage
      return <ProductImage src={image?.link} width={26} height={26} className={s['image-cell-wrapper']} />
    }
  },
  {
    accessorKey: 'name',
    header: '',
    minSize: 300
  },
  {
    accessorKey: 'user.name',
    header: 'Имя',
    size: 150,
    minSize: 150,
    enableSorting: false,
    cell: (info: CellContext<TPartnerProgramList, unknown>) => info?.getValue()
  },
  {
    accessorKey: 'createdAt',
    header: 'Дата создания',
    size: 150,
    minSize: 150,
    cell: (info: CellContext<TPartnerProgramList, unknown>) => moment(info?.getValue() as string).format('DD.MM.YY')
  },
  {
    accessorKey: 'status',
    header: 'Статус',
    size: 150,
    minSize: 150,
    cell: (info: CellContext<TPartnerProgramList, unknown>) => (
      <OrderStatusLabel status={paymentStatus[info?.getValue() as keyof typeof paymentStatus]} />
    )
  }
]

const PartnersPage = () => {
  const { getQueryParams, setQueryParams, deleteQueryParams } = useQueryParams()
  const activeTab = tabs.findIndex((v: ITab) => v.key === getQueryParams('filter')) ?? 0
  const activeTabFilter = getQueryParams('filter')

  const [totalCount, setTotalCount] = useState<number>(0)
  const [activeTabIndex, setActiveTabIndex] = useState<number>(activeTab)
  const [sorting, setSorting] = useState<SortingState | undefined>(undefined)
  const [tabsCount, setTabsCount] = useState<TProductsTabCount>()
  const [programId, setProgramId] = useState<string | undefined>(undefined)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false)
  const { activeShop } = useAppSelector(({ store }) => store.seller)

  useQuery({
    queryKey: ['getProductsCount', activeShop?._id],
    queryFn: async () => {
      const { data } = await $api.get(`/api/affiliate/programs/count/shopId/${activeShop?._id}`)
      setTabsCount(data ?? {})
      return data
    }
  })

  const { programs = [], isError, isLoading, refetch, refetchPayments } = useGetTableData({ setTotalCount })

  const closeCreateModal = () => {
    setIsCreateModalOpen(false)
  }

  const setFirstPage = () => setQueryParams('page', '1')

  const onPaginationChange = (value: number) => setQueryParams('page', value.toString())

  const onLimitChange = (value: number) => {
    setQueryParams('limit', String(value))
    setFirstPage()
  }

  const onSearchChange = (searchString: string) => {
    if (!searchString) return deleteQueryParams('text')
    setQueryParams('text', searchString)
  }

  const onTabChange = (index: number) => {
    setQueryParams('filter', tabs[index].key)
    setActiveTabIndex(index)
  }

  useEffect(() => {
    const sortQueryParams = getQueryParams('sort')

    if (sortQueryParams) {
      if (sortQueryParams === 'productName_asc') setSorting([{ id: 'name', desc: false }])
      if (sortQueryParams === 'productName_desc') setSorting([{ id: 'name', desc: true }])
      if (sortQueryParams === 'endDate_asc') setSorting([{ id: 'endDate', desc: false }])
      if (sortQueryParams === 'endDate_desc') setSorting([{ id: 'endDate', desc: true }])
      if (sortQueryParams === 'discountPercentage_asc') setSorting([{ id: 'discountPercentage', desc: false }])
      if (sortQueryParams === 'discountPercentage_desc') setSorting([{ id: 'discountPercentage', desc: true }])
      if (sortQueryParams === 'createdAt_asc') setSorting([{ id: 'createdAt', desc: false }])
      if (sortQueryParams === 'createdAt_desc') setSorting([{ id: 'createdAt', desc: true }])
      if (sortQueryParams === 'status_asc') setSorting([{ id: 'status', desc: false }])
      if (sortQueryParams === 'status_desc') setSorting([{ id: 'status', desc: true }])
    }

    if (!activeTabFilter) {
      setQueryParams('filter', tabs[0].key)
      setActiveTabIndex(0)
    }
  }, [])

  useEffect(() => {
    if (sorting) {
      const sortingKeys = {
        name: ['productName_asc', 'productName_desc'],
        endDate: ['endDate_asc', 'endDate_desc'],
        discountPercentage: ['discountPercentage_asc', 'discountPercentage_desc'],
        createdAt: ['createdAt_asc', 'createdAt_desc'],
        status: ['status_asc', 'status_desc']
      } as Record<string, TSort[]>

      const correctKey = sortingKeys?.[sorting?.[0]?.id]?.[sorting?.[0]?.desc ? 1 : 0]

      if (correctKey) {
        setQueryParams('sort', correctKey)
      } else {
        deleteQueryParams('sort')
      }

      setFirstPage()
    }
  }, [sorting])

  if (isError) return <PageEmpty title='Не удалось получить данные. Попробуйте еще раз' />

  return (
    <div className={s.products}>
      <PageHeader
        title='Партнерская программа'
        button={<Button onClick={() => setIsCreateModalOpen(true)}>Создать</Button>}
        className={s['products__header-wrapper']}
      >
        <div className={s.products__header}>
          <PageTabs>
            {tabs.map((v: ITab, i: number) => (
              <PageTabs.Tab<number>
                key={v.name}
                tabKey={i}
                title={v.name}
                active={activeTabIndex}
                onChange={onTabChange}
                labelColor={v?.labelColor}
                disabled={v?.disabled}
                label={String((tabsCount as any)?.[v?.key] ?? 0)}
              />
            ))}
          </PageTabs>
          {activeTabIndex !== 2 && (
            <Search onChange={onSearchChange} disabled={!programs?.length && !getQueryParams('text')} />
          )}
        </div>
      </PageHeader>
      <div className={s.products__content}>
        {isLoading ? (
          <TableSkeleton />
        ) : programs?.length ? (
          <Table<TPartnerProgramList>
            defaultData={programs || []}
            defaultColumns={activeTabIndex === 2 ? columnsForPayments : columns}
            onSorting={setSorting}
            sorting={sorting || []}
            onRowClick={(data) => setProgramId(data?.program || data._id)}
            resize
            fluid
            shouldChangeColumnsWhenTabChanges
          />
        ) : (
          <PageEmpty title='Нет данных' fullHeight={false} />
        )}
      </div>
      {Boolean(programs?.length) && (
        <div className={s.products__footer}>
          <Pagination
            totalCount={totalCount}
            pageIndex={parseInt(getQueryParams('page') ?? '1', 10)}
            onPage={parseInt(getQueryParams('limit') ?? '20', 10)}
            onChange={onPaginationChange}
          />
          <div className={s.limit}>
            <DropDownList<number>
              value={Number(getQueryParams('limit') ?? 20)}
              options={[10, 20, 30, 40, 50]}
              onSelect={onLimitChange}
              label='Количество Товаров'
            />
          </div>
        </div>
      )}

      <Modal size='sm' isOpen={isCreateModalOpen} onRequestClose={closeCreateModal}>
        <PartnerModal refetchTable={refetch} onClose={closeCreateModal} />
      </Modal>
      <Drawer show={Boolean(programId)} onClose={() => setProgramId(undefined)} width='613px' closeOnOutSide={false}>
        <PartnerDrawer refetchTable={refetchPayments} activeTabFilter={activeTabFilter} programId={programId || ''} />
      </Drawer>
    </div>
  )
}

export default PartnersPage
