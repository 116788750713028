import React, { SetStateAction, useCallback } from 'react'
import cx from 'classnames'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { useMessage } from 'hooks/useMessage'
import { Icon, Text } from 'components/UI'
import $api from 'components/http/axios'
import s from './upload-shop-icon.module.scss'

interface IUploadShopIconProps {
  sellerId: string | null
  image: string
  setImage: React.Dispatch<SetStateAction<string>>
  onSuccess: VoidFunction
}

const UploadShopIcon = ({ sellerId, image, setImage, onSuccess }: IUploadShopIconProps) => {
  const onDrop: any = useCallback(
    async (acceptedFiles: File[]) => {
      const loadingToast = useMessage('', 'loading', `Загрузка изображения`)

      const showUpdateMessage = (message: string, type: 'error' | 'success' = 'error') =>
        toast.update(loadingToast, {
          autoClose: 3000,
          type,
          isLoading: false,
          render: message
        })

      try {
        const newFiles: File[] = []
        const formData = new FormData()

        acceptedFiles.forEach((file: File) => {
          if (file.size > 3 * 1024 * 1024) {
            return showUpdateMessage(`Превышен размер фотографии ${file.name}`)
          }

          newFiles.push(file)
          formData.append('images', file)
        })

        if (newFiles.length) {
          const response = await $api.post(`/api/seller/${sellerId}/uploadIcon`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })

          if (response.status === 201) {
            await $api.patch(
              `/api/seller/${sellerId}/update`,
              { image: response.data.link },
              {
                headers: { 'Content-Type': 'application/json' }
              }
            )
            setImage(response.data.link)
            await onSuccess()
            showUpdateMessage('Изображение успешно загружено', 'success')
          }
        }
      } catch (e) {
        showUpdateMessage('Ошибка при загрузке изображения')
      }
    },
    [image]
  )

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'image/jpeg': ['.jpeg'],
      'image/png': ['.png'],
      'image/jpg': ['.jpg']
    }
  })

  if (image)
    return (
      <div className={s['image-item']}>
        <img src={image} className={s.uploadShopIcon__image} alt='shop' />
        <div className={s['image-item__close']} {...getRootProps()}>
          <Icon name='edit' color='white' size='xs' />
        </div>
      </div>
    )

  return (
    <div
      className={cx(s.uploadShopIcon, {
        [s['dropzone--accept']]: isDragAccept,
        [s['dropzone--reject']]: isDragReject,
        [s.disabled]: Boolean(image)
      })}
      {...getRootProps()}
    >
      <div
        className={cx(s.uploadShopIcon__icon, {
          [s['uploadShopIcon__icon--accept']]: isDragAccept,
          [s['uploadShopIcon__icon--reject']]: isDragReject
        })}
      >
        <Icon name='image-square' size='xxl' color='dark-grey' />
      </div>
      <div
        className={cx(s.uploadShopIcon__title, {
          [s['uploadShopIcon__title--accept']]: isDragAccept,
          [s['uploadShopIcon__title--reject']]: isDragReject
        })}
      >
        {isDragAccept ? (
          <Text size='sm' className={s.uploadShopIcon__info}>
            Все хорошо! Опустите файлы здесь.
          </Text>
        ) : isDragReject ? (
          <Text size='sm' className={s.uploadShopIcon__info}>
            Неподходящий размер или формат файла
          </Text>
        ) : (
          <>
            <input style={{ display: 'none' }} {...getInputProps()} />
            <Text color='middle-grey' cursor='auto'>
              Иконка магазина
            </Text>
          </>
        )}
      </div>
      <Text as='p' className={s.uploadShopIcon__description} color='extra-grey'>
        Размер — 512x512 px.
      </Text>
      <Text as='p' className={s.uploadShopIcon__description} color='extra-grey'>
        Формат — PNG.
      </Text>
    </div>
  )
}

export default UploadShopIcon
