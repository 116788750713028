import React, { useState } from 'react'
import cx from 'classnames'
import imgBase from 'public/default-product-image.png'
import s from './product-image.module.scss'

interface IProductImageProps {
  src: string
  width?: number | string
  height?: number | string
  className?: string
  border?: boolean
  fit?: 'cover' | 'contain'
}

const ProductImage = ({ src, width, height, className, border = true, fit }: IProductImageProps) => {
  const [imageSrc, setImageSrc] = useState<string>(src ?? imgBase)

  return (
    <div className={cx(s['product-image'], { [s.border]: border }, className)} style={{ width, height }}>
      <div className={cx(s['product-image__image'], { [s[`object-fit-${fit}`]]: fit })}>
        <img src={imageSrc} onError={() => setImageSrc(imgBase)} alt='product' />
      </div>
    </div>
  )
}

export default React.memo(ProductImage)
