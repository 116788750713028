import { useQuery } from '@tanstack/react-query'
import $api from 'components/http/axios'
import { useMessage, useQueryParams } from 'hooks'
import { useAppSelector } from 'redux/hook'
import { PartnersTabKeys } from '../consts'

interface IUseGetTableData {
  setTotalCount: (num: number) => void
}
export const useGetTableData = ({ setTotalCount }: IUseGetTableData) => {
  const { getQueryParams, searchParams } = useQueryParams()
  const { activeShop } = useAppSelector(({ store }) => store.seller)

  searchParams.delete('product')

  const {
    data: mainPrograms = [],
    isError: isMainProgramsError,
    isLoading: isMainProgramsLoading,
    refetch
  } = useQuery({
    queryKey: ['getMainPrograms', activeShop?._id, ...searchParams.entries()],
    queryFn: async () => {
      const { data, status } = await $api.get(
        `/api/affiliate/programs/shopId/${activeShop?._id}?${new URLSearchParams(searchParams).toString()}`
      )
      if (status !== 200) return useMessage('Ошибка получения программ!', 'error')
      setTotalCount(data?.totalCount || 0)
      return data?.programs || []
    },
    retry: 2,
    enabled: [PartnersTabKeys.Active, PartnersTabKeys.Archived].includes(getQueryParams('filter') as PartnersTabKeys)
  })

  const {
    data: paymentsPrograms = [],
    isError: isPaymentsProgramsError,
    isLoading: isPaymentsProgramsLoading,
    refetch: refetchPayments
  } = useQuery({
    queryKey: ['getPaymentsPrograms', activeShop?._id, ...searchParams.entries()],
    queryFn: async () => {
      const { data, status } = await $api.get(
        `/api/affiliate/withdrawals/shopId/${activeShop?._id}?${new URLSearchParams(searchParams).toString()}`
      )
      if (status !== 200) return useMessage('Ошибка получения программ!', 'error')
      setTotalCount(data?.totalCount || 0)
      return data?.withdrawals || []
    },
    retry: 2,
    enabled: Boolean(getQueryParams('filter') === PartnersTabKeys.Withdrawals)
  })

  if (getQueryParams('filter') === PartnersTabKeys.Withdrawals) {
    return {
      programs: paymentsPrograms,
      isError: isPaymentsProgramsError,
      isLoading: isPaymentsProgramsLoading
    }
  }
  return {
    programs: mainPrograms,
    isError: isMainProgramsError,
    isLoading: isMainProgramsLoading,
    refetch,
    refetchPayments
  }
}
