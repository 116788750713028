import { ColumnDef } from '@tanstack/react-table'
import { Table } from 'components/UI'
import React from 'react'
import { IUser } from 'redux/types'

const columns: ColumnDef<any>[] = [
  {
    accessorKey: 'index',
    header: '№',
    size: 44,
    enableSorting: false
  },
  {
    accessorKey: 'user.name',
    header: 'Партнеры',
    size: 320,
    minSize: 320
  },
  {
    accessorKey: 'sales',
    header: 'Продажи',
    size: 95,
    minSize: 95
  },
  {
    accessorKey: 'earnings',
    header: 'Баллы',
    size: 95,
    minSize: 95
  }
]

interface IPartnerTableContent {
  partners: { _id: string; user: IUser; sales: number; earnings: number }[]
}

const TableContent = ({ partners }: IPartnerTableContent) => (
  <Table defaultData={partners} defaultColumns={columns} resize fluid />
)

export default TableContent
