import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation, Pagination, Thumbs } from 'swiper'
import cx from 'classnames'
import { setSpaceBetweenCharacters } from 'helpers'
import { IProductImage } from 'models'
import { useAppSelector } from 'redux/hook'
import { Button, Icon, Modal, Text } from 'components/UI'
import { ProductCharacteristics } from './productCharacteristics'
import { ProductImage } from '../productImage'
import s from './preview-card.module.scss'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'

interface IPreviewCardProps {
  data: any
  isOpen: boolean
  onClose: VoidFunction
}

const PreviewCard = ({ data, isOpen, onClose }: IPreviewCardProps) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null)
  const descriptionRef = useRef<HTMLElement | null>(null)
  const characteristicsRef = useRef<HTMLElement | null>(null)

  const brand = data?.characteristics.find((c: { title: string }) => c.title === 'Бренд')
  const { activeShop } = useAppSelector(({ store }) => store.seller)

  const scrollToElement = (refElement: any) => {
    if (!refElement?.current) return
    refElement.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} size='xxl' closePlace='uptop-right'>
      {Boolean(data) && (
        <div className={s['preview-card']}>
          <Text className={s['product-name']}>{data.productName}</Text>
          <div className={s.header}>
            <div className={s.header__block}>
              <Icon size='md' name='star' color='middle-grey' />
              <Text size='md' family='secondary'>
                Нет оценок
              </Text>
            </div>
            <div className={s.header__block}>
              <Icon name='heart' size='md' />
              <Text size='md' family='secondary'>
                В избранное
              </Text>
            </div>
            <div className={s.header__block}>
              <Icon name='share' size='md' />
              <Text size='md' family='secondary'>
                Поделиться
              </Text>
            </div>
          </div>
          <div className={s.content}>
            <div className={s.content__image_block}>
              <div className={s.slider}>
                <Swiper
                  direction='vertical'
                  initialSlide={0}
                  onSwiper={setThumbsSwiper}
                  spaceBetween={6}
                  slidesPerView='auto'
                  freeMode
                  watchSlidesProgress
                  slideToClickedSlide
                  modules={[FreeMode, Thumbs]}
                  className={s['slider-wrapper']}
                >
                  {data.images.slice(0, 4).map((image: IProductImage) => (
                    <SwiperSlide
                      key={image._id}
                      style={{
                        width: '92px',
                        height: '92px'
                      }}
                    >
                      <div key={image._id} className={s.slider__item}>
                        <ProductImage src={image.link} width='100%' height='100%' />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                {data.images.length > 4 && (
                  <div className={s.slider__image_more}>
                    <Text size='md' color='blue' align='center' weight='regular'>
                      Еще {data.images.length - 3}
                    </Text>
                  </div>
                )}
              </div>
              <Swiper
                initialSlide={0}
                mousewheel={false}
                allowTouchMove={false}
                thumbs={{
                  swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null
                }}
                modules={[FreeMode, Navigation, Thumbs, Pagination]}
                pagination
              >
                {data.images.map((items: IProductImage, index: number) => (
                  <SwiperSlide key={items._id} virtualIndex={index} className='gallery_slide'>
                    <ProductImage src={items.link} className={s['main-photo']} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className={s.content__info_block}>
              <div className={s.description}>
                <Text size='md' family='secondary' className={s.description__text}>
                  {data.description}
                </Text>
                {data.description.length > 250 && (
                  <Button view='link' onClick={() => scrollToElement(descriptionRef)} className='offset-top-12'>
                    Читать далее
                  </Button>
                )}
              </div>
              <div className='offset-top-24'>
                <ProductCharacteristics
                  onScrollTo={() => scrollToElement(characteristicsRef)}
                  count={10}
                  data={data.characteristics}
                />
              </div>
              <div className='offset-top-24'>
                <div className={s['brand-name']}>
                  <Text size='md'>{activeShop?.name}</Text>
                  <Icon name='arrow-right' size='xs' color='middle-grey' />
                </div>
                {brand?.value?.[0] && (
                  <div className={cx('offset-top-8', s['brand-name'])}>
                    <Text family='secondary'>
                      Все товары бренда
                      <Text style={{ marginLeft: 4 }} family='primary'>
                        {brand.value[0].toLocaleUpperCase()}
                      </Text>
                    </Text>
                    <Icon name='arrow-right' size='xs' color='middle-grey' />
                  </div>
                )}
              </div>
            </div>
            <div className={s.content__pay_block}>
              <div className={s.price}>
                <Text className={s.price__text}>{setSpaceBetweenCharacters(data.discountPrice)} ₽</Text>
                <Button className={s.price__button} fluid>
                  Купить
                </Button>
                <div className={s.price__shipping_data}>
                  <Text size='xs' family='secondary' color='middle-grey'>
                    Самовывоз:
                    <Text size='xs' family='secondary' color='primary' style={{ marginLeft: 4 }}>
                      Бесплатно
                    </Text>
                  </Text>
                </div>
              </div>
              <div className={s.shop}>
                <img className={s.shop__avatar} src={activeShop?.image as string} alt='shop-avatar' />
                <Text size='xs'>{activeShop?.name}</Text>
              </div>
            </div>
          </div>
          <section className={s.description_section} ref={descriptionRef}>
            <Text as='h1'>Описание</Text>
            <Text as='p' className='offset-top-12' family='secondary'>
              {data.description}
            </Text>
          </section>
          <section className={s.characteristics_section} ref={characteristicsRef}>
            <Text as='h1'>Характеристики</Text>
            <div className='offset-top-12'>
              <ProductCharacteristics
                onScrollTo={() => scrollToElement(characteristicsRef)}
                count={data.characteristics.length}
                data={data.characteristics}
              />
            </div>
          </section>
        </div>
      )}
    </Modal>
  )
}

export default PreviewCard
